// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/ProductDescription.json');
export interface ServiceDataResponse {
    id: string,
    type: string,
    attributes: {
      id: number,
      title: string,
      description: string
      title_arabic: string;
      description_arabic: string;
    }
  };

  interface Document {
    file_name: string;
    url: string;
    size: string;
  }

  export type BannerAttributes = {
    id: number;
    type: string;
    attributes: {
      id: number;
      banner_title: string;
      banner_title_arabic: string;
      banner_description: string;
      banner_description_arabic: string;
      created_at: string;
      updated_at: string;
      image: string;
      sub_text1: string;
      sub_text2: string;
    };
  };

  interface UserListing {
    id: number;
    type: string;
    attributes: Attributes;
  }
  interface Attributes {
    id: number;
    total_number_of_debts: number;
    total_amount_of_debts: number;
    total_judgements: number;
    last_collectors_contact: number;
    last_creditors_contact: number;
    collections_used_in_past: number;
    total_months_as_costomer: number;
    pas_payment_made: boolean;
    month_average_paytime: number;
    other_documents: Document[][];
    account_id: number;
    supporting_documents: Document[][];
    portfolio_size: string;
    listing_title: string;
    avg_balance_per_account: number;
    account_count: number;
    age_of_dept: string;
    type_of_dept: string;
    current_recovery_status: string;
    status: string;
    sub_status: string;
    confirmed: boolean;
    created_at: string;
    offer_count: number;
  }
  interface ListingData {
    id: number;
    type: string;
    attributes: ListingAttributes;
  }
  interface ListingAttributes {
    id: number,
    portfolio_size: string,
    listing_title: null,
    avg_balance_per_account: string,
    account_count: number,
    total_number_of_debts: number,
    total_amount_of_debts: number,
    total_judgements: number,
    total_months_as_costomer: number,
    pas_payment_made: boolean,
    month_average_paytime: number,
    last_collectors_contact: string,
    last_creditors_contact: string,
    collections_used_in_past: number,
    age_of_dept: string,
    type_of_dept: string,
    current_recovery_status: string,
    status: string,
    sub_status: string,
    confirmed: boolean,
    account_id: number,
    created_at: string,
    supporting_documents: [][],
    offer_count: number
  }
  interface BuyersProfile {
    id: number;
    type: string;
    attributes: BuyerAttributes;
  }
  
  interface BuyerAttributes {
    name: string;
    website: string;
    industry: string;
    registration: number,
    country: string;
    state: string;
    city: string;
    zip: number;
    services: [];
    bio: string;
    account_id: number;
    confirmed: boolean;
    image: Document;
    documents: Document[][];
  }

  export interface ServiceListApiResponse {

    id: string,
    type: string,
    attributes: {
      company_name: string,
      company_name_arabic: string;
      about_us: string,
      about_us_arabic: string;
      unifying_number: string,
      is_verified_provider: false,
      verification_response: string,
      company_image: { [key: string]: string } | null,
      user_account: {
        id: number,
        email: string
      },
      tabs: [
        {
          achievements: null,
          achievements_arabic: null
        },
        {
          conditions: null,
          conditions_arabic: null
        },
        {
          steps: null,
          steps_arabic: null
        }
      ],
      documents: {
        business_registration: {
          file_name: string,
          url: string,
          size: string
        },
        tax_registration: {
          file_name: string,
          url: string,
          size: string
        },
        license_certification: {
          file_name: string,
          url: string,
          size: string
        }
      }
    }
  }
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: Record<string, string>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage: string,
    serviceData: Array<ServiceDataResponse>;
    bannerData: BannerAttributes;
    currentPage: number;
    itemsPerPage: number;
    search: string;
    expanded: {
        debtSize: boolean;
        debtCategory: boolean;
        debtAgeRange: boolean;
        debtOwnerType: boolean;
        experienceLevel: boolean;
        debtServicesOffered: boolean;
        buyerType: boolean;
    };
    experienceLevel: {
      under1year: boolean;
      between1and3years: boolean;
      between3and5years: boolean;
      over5years: boolean;
    },
    debtServicesOffered: {
      debtCollection: boolean;
      legalServices: boolean;
      investmentServices: boolean;
      financialAdvisory: boolean;
    },
    buyerType: {
      individual: boolean;
      bank: boolean;
      agency: boolean;
      serviceProvider: boolean;
    },
    debtSize: {
        under10000: boolean,
        between10000and50000: boolean,
        between50000and100000: boolean,
        over100000: boolean
    };
    debtAgeRanges: {
        under30days: boolean,
        from30to60days: boolean,
        from60to90days: boolean,
        over90days: boolean,
    },
    debtOwnerTypes: {
        individual: boolean,
        bank: boolean,
        agency: boolean,
    },
    activeBtn: string;
    activeBtn1: string;
    token: string;
    filteredData: {};
    filteredBuyerData: {};
    userList: UserListing[];
    singleListingData: Array<ListingData>;
    totalPages: number,
    totalCount: number,
    buyersProfile: BuyersProfile[];
    currentBuyersPage: number,
    totalBuyersPages: number,
    totalBuyersCount: number,
    selectedRequest: number;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ServiceTypeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    fetchOfferApiCall: string = "";
    fetchBuyerProfileApiCall: string = "";
    fetchOfferFilterApiCall: string = "";
    fetchBuyersFilterApiCall: string = "";
    getMarketPlaceBannerCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIRequestBodyMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage: configJSON.EN,
            currentPage: 1,
            itemsPerPage: 10, 
            serviceData: [],
            bannerData: {
                id: 0,
                type: "",
                attributes: {
                  id: 0,
                  banner_title: "",
                  banner_title_arabic: "",
                  banner_description: "",
                  banner_description_arabic: "",
                  created_at: "",
                  updated_at: "",
                  image: "",
                  sub_text1: "",
                    sub_text2: "",
                },
            },
            expanded: {
                debtSize: true,
                debtCategory: true,
                debtAgeRange: true,
                debtOwnerType: true,
                experienceLevel: true,
                debtServicesOffered: true,
                buyerType: true,
            },
            experienceLevel: {
              under1year: false,
              between1and3years: false,
              between3and5years: false,
              over5years: false,
            },
            debtServicesOffered: {
              debtCollection: false,
              legalServices: false,
              investmentServices: false,
              financialAdvisory: false,
            },
            buyerType: {
              individual: false,
              bank: false,
              agency: false,
              serviceProvider: false,
            },
            debtSize: {
                under10000: false,
                between10000and50000: false,
                between50000and100000: false,
                over100000: false
            },
            debtAgeRanges: {
                under30days: false,
                from30to60days: false,
                from60to90days: false,
                over90days: false,
            },
            debtOwnerTypes: {
                individual: false,
                bank: false,
                agency: false,
            },
              search: "",
              activeBtn: "Listings",
              activeBtn1: "Buyer profiles",
              token: "",
              filteredData: {},
              filteredBuyerData: {},
              userList: [],
              buyersProfile: [],
              singleListingData: [],
              totalPages: 1, 
              totalCount: 0,
              currentBuyersPage: 1, 
              totalBuyersPages: 1, 
              totalBuyersCount: 0,
              selectedRequest: 0,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getMarketPlaceBannerCallID != null &&
            this.getMarketPlaceBannerCallID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors && responseJson.data) {
              this.setState({ bannerData: responseJson.data[0] });
            }
          }
          if(apiRequestCallId === this.fetchOfferFilterApiCall){
            this.setState({ userList: responseJson.data })            
          }
          if (responseJson && !responseJson.errors) {
            if (apiRequestCallId === this.fetchOfferApiCall) {
              const { data, meta } = responseJson;
              
              this.setState({
                userList: data, 
                totalPages: meta.total_pages,
                totalCount: meta.total_count,
                currentPage: meta.current_page,
              });
            }
          }
          
          if (apiRequestCallId === this.fetchBuyerProfileApiCall) {
            const { data, meta } = responseJson;
              this.setState({
                buyersProfile: data, 
                totalBuyersPages: meta.total_pages,
                totalBuyersCount: meta.total_count,
                currentBuyersPage: meta.current_page,
              });
          }
      
          if(apiRequestCallId === this.fetchBuyersFilterApiCall){
            this.setState({ buyersProfile: responseJson.data })
          }
    
    
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
      const language = await utills.getLanguageOrSetCurrentLanguage();
      this.setState({ currentLanguage: language, activeBtn: language === 'en' ? 'Listings' : 'شركات' });
      this.setState({ currentLanguage: language, activeBtn1: language === 'en' ? 'Buyer profiles' : 'عروض' });
      const token = await getStorageData("accessToken");   
      this.setState({
        token: token
      })
      this.MarketPlaceGetBanner();
      this.fetchOffers();
    }


    MarketPlaceGetBanner = () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMarketPlaceBannerCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.MarketPlaceBannerID
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search: event.target.value
        })
    }

    handleTab = (tabName: string) => {
        this.setState({ activeBtn: tabName });
        this.setState({ activeBtn1: tabName });
        if (tabName === "Listings" || tabName === 'عروض') {
          this.fetchOffers()
        }
        if (tabName === "Buyer profiles" || tabName === 'عروض') {
          this.fetchBuyerProfiles()
        }
      }

      fetchOffers = (page = 1, itemsPerPage = 10) => {
        const headers = {
          "Content-Type": configJSON.loginApiContentType,
          token: this.state.token
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.fetchOfferApiCall = requestMessage.messageId;
      
        const endpoint =`${configJSON.ListingsEndPoints}?page=${page}&per_page=${itemsPerPage}`;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      fetchBuyerProfiles = (page = 1 , itemPerPage = 10) => {
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.fetchBuyerProfileApiCall = requestMessage.messageId;
    
        const endpoint =`${configJSON.BusinessEndPoints}?page=${page}&per_page=${itemPerPage}`;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      fetchOffersFilterData = () => {
        const headers = {
          "Content-Type": configJSON.loginApiContentType,
          token: this.state.token
        };
    
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchOfferFilterApiCall = requestMessage.messageId;
    
    
        const queryParams = new URLSearchParams(this.state.filteredData).toString();
    
        const endpoint = `${configJSON.ListingsEndPoints}?${queryParams}`
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      fetchBuyersFilterData = () => {
        const headers = {
          "Content-Type": configJSON.loginApiContentType,
          token: this.state.token
        };
    
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.fetchBuyersFilterApiCall = requestMessage.messageId;
    
    
        const queryParams = new URLSearchParams(this.state.filteredBuyerData).toString();
    
        const endpoint = `${configJSON.BusinessEndPoints}?${queryParams}`
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      handleAccordionChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState((prevState) => ({
          expanded: {
            ...prevState.expanded,
            [panel]: isExpanded,
          },
        }));
      };

      handleInputChange = (field: string, name: string, value: string, currentState: string, subCurrentState: string) => {
        this.setState((prevState:any) => {
          const updatedState = { ...prevState };
          const current = updatedState[currentState];
          if (current) {
            Object.keys(current).forEach((key) => {
              current[key] = false;
            });
            current[subCurrentState] = true;
            updatedState.filteredData = {
              ...prevState.filteredData,
              [field]: name
            };
          }      
          return updatedState;
        });
      };


      handleBuyerInputChange = (field: string, name: string, value: string, currentState: string, subCurrentState: string) => {
        this.setState((prevState:any) => {
          const updatedState = { ...prevState };
          const current = updatedState[currentState];
          if (current) {
            Object.keys(current).forEach((key) => {
              current[key] = false;
            });
            current[subCurrentState] = true;
            updatedState.filteredBuyerData = {
              ...prevState.filteredBuyerData,
              [field]: name
            };
          }      
          return updatedState;
        });
      };

      handleNavigate = (card: any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.SessionResponseData), { card })
        this.send(message);
        this.setState({ singleListingData: card });    
      }

      timeAgo = (date: string): string => {
        const now = new Date();
        const createdAt = new Date(date);
        const differenceInMilliseconds = now.getTime() - createdAt.getTime();
        const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    
        if (differenceInMinutes < 1) return "Just now";
        if (differenceInMinutes < 60) return `${differenceInMinutes} minutes ago`;
    
        const differenceInHours = Math.floor(differenceInMinutes / 60);
        if (differenceInHours < 24) return `${differenceInHours} hours ago`;
    
        const differenceInDays = Math.floor(differenceInHours / 24);
        if (differenceInDays < 30) return `${differenceInDays} days ago`;
    
        const differenceInMonths = Math.floor(differenceInDays / 30);
        if (differenceInMonths < 12) return `${differenceInMonths} months ago`;
    
        const differenceInYears = Math.floor(differenceInMonths / 12);
        return `${differenceInYears} years ago`;
      };

      handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if(page >= 1 && page <= this.state.totalPages) {
            this.setState({ currentPage: page }, () => {
                this.fetchOffers(this.state.currentPage);
            });
        }
    };

    handleListSummery = (card: any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ListingSummary");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), { card });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
    };

    handleListingDetails = (card: any) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { card });
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
  };

    handleBuyersPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if(page >= 1 && page <= this.state.totalPages) {
            this.setState({ currentBuyersPage: page }, () => {
                this.fetchBuyerProfiles(this.state.currentBuyersPage);
            });
        }
    };
    // Customizable Area End
}
