import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import { Box, Button, IconButton, InputAdornment, Grid,Paper, TableContainer, TextField, Typography, Menu, MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VerificationRequestManagementController, { Props } from "./VerificationRequestManagementController.web";
import { acceptImg, rejectImg, pendingImg, } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/RequestVerificaion.json')
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

    // Customizable Area End
}

export class VerificationRequestManagement extends VerificationRequestManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderFilter = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <Box style={classes.filterWrapper}>
                <Box style={classes.filterCloseBox}>
                    <Box style={classes.filterHeadingBox}>
                        <FilterListRoundedIcon style={{ width: "20px" }} />
                        {languageData.Filters}
                    </Box>
                    <IconButton data-test-id="filterCloseTestID" onClick={this.handleCloseFilter}>
                        <CloseRoundedIcon style={{ width: "20px", color: "#0F172A" }} />
                    </IconButton>
                </Box>
                <Box style={classes.filterMiddleBox}>
                    <Box>
                        <FilterResetWrapper>
                            <Button>{languageData.Reset}</Button>
                        </FilterResetWrapper>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Box>
                                <Typography style={classes.filterLabel}>{languageData.From}</Typography>
                                <FilterDateField
                                    type="date"
                                    variant="outlined"
                                />
                            </Box>
                            <Box>
                                <Typography style={classes.filterLabel}>{languageData.To}</Typography>
                                <FilterDateField
                                    type="date"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <FilterResetWrapper>
                            <Button
                                data-test-id="statusResetTestID"
                                onClick={() => this.handleSelectReset("status")}>
                                {languageData.Reset}
                            </Button>
                        </FilterResetWrapper>
                        <Typography style={{ ...classes.filterLabel, marginBottom: "4px" }}>{languageData.Status}</Typography>
                        <FormControl fullWidth>
                            <FilterInputLabel style={{ display: this.state.status ? "none" : "block", lineHeight: "0.5" }}>
                                {languageData.Status}
                            </FilterInputLabel>
                            <FilterSelect
                                fullWidth
                                data-test-id="statusTestID"
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                value={this.state.status}
                                name="status"
                                onChange={(event) => this.handleSelect(event, "status")}>
                                <MenuItem value={"option 1"}>{languageData.Accepted}</MenuItem>
                                <MenuItem value={"option 2"}>{languageData.Rejected}</MenuItem>
                                <MenuItem value={"option 3"}>{languageData.Pending}</MenuItem>
                            </FilterSelect>
                        </FormControl>
                    </Box>
                </Box>
                <Box style={classes.filterBottomBox}>
                    <ResetButton
                        data-test-id="filterResetButtonTestID"
                        onClick={this.handleFilterReset}
                    >
                        {languageData.Reset}
                    </ResetButton>
                    <CreateButton
                        data-test-id="applyNowTestID"
                        style={{ fontSize: "14px", height: "44px" }}
                        onClick={this.handleApplyNow}
                    >
                        {languageData.Applynow}
                    </CreateButton>
                </Box>
            </Box>
        );
    };

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage, dot,currentPage,itemsPerPage,verifications,statusCounts } = this.state;
        const indexOfLastUser = currentPage * itemsPerPage;
        const indexOfFirstUser = indexOfLastUser - itemsPerPage;
        const currentVerifications = verifications.slice(indexOfFirstUser, indexOfLastUser);
        const languageData = languageTranslationData[currentLanguage]
        return (
            <UserProfileDrawer
                handleClick={this.handleDrawerNavigation2}
                pageName={this.state.userType === "consumer" ? "UserRequests" : "dashboardVerificationRequest"}
                breadScrumArray={this.state.breadscrumValues}
                data-test-id="drawerTestID"
                userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}
                navigation={this.props.navigation}>
                <Box>
                    <Typography style={classes.typographyHeader}>{languageData.Title}</Typography>
                    <Box style={classes.vMainBox}>
                        <InnerBox>
                            <img style={classes.vImg} src={acceptImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{languageData.Approved}</Typography>
                                <Typography style={classes.vApprovedNumber}>{statusCounts.approved || 0}</Typography>
                            </InnerTextBox>
                        </InnerBox>
                        <InnerBox>
                            <img style={classes.vImg} src={rejectImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{languageData.Rejected}</Typography>
                                <Typography style={classes.vApprovedNumber}>{statusCounts.rejected || 0}</Typography>
                            </InnerTextBox>

                        </InnerBox>

                        <InnerBox>
                            <img style={classes.vImg} src={pendingImg} />
                            <InnerTextBox>
                                <Typography style={classes.vApprovedText}>{languageData.Pending}</Typography>
                                <Typography style={classes.vApprovedNumber}>{statusCounts.pending || 0}</Typography>
                            </InnerTextBox>

                        </InnerBox>

                    </Box>

                    <Box style={classes.VarificationTopBox}>
                        <TableUpperBox>
                            <CustomTextFieldForm
                                data-test-id="inputSearchID"
                                onChange={(event) => this.handleSearch(event)}
                                variant="outlined"
                                placeholder={languageData.Search}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={classes.searchIcon} />
                                        </InputAdornment>
                                    ),

                                }}
                            />
                            <Box style={{ width: "348px", position: "relative", display: "flex", justifyContent: "end" }}>
                                <FilterButton
                                    style={{ height: "44px" }}
                                    data-test-id="filterButtonTestID"
                                    onClick={this.handleToggleFilter}

                                >
                                    <Box style={classes.createButton}>
                                        <FilterListRoundedIcon />
                                        {languageData.Filters}
                                    </Box>
                                </FilterButton>

                                {this.state.showFilter && this.renderFilter()}
                            </Box>
                        </TableUpperBox>
                        <TableContainer style={classes.VarificationTable} component={Paper}>
                            <Table style={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <CustomHeadingRow>
                                        <TableCell>{languageData.RequestId}</TableCell>
                                        <TableCell>{languageData.Name}</TableCell>
                                        <TableCell>{languageData.Registrationnumber}</TableCell>
                                        <TableCell>{languageData.Date}</TableCell>
                                        <TableCell>{languageData.Status}</TableCell>

                                    </CustomHeadingRow>
                                </TableHead>
                                <TableBody>
                                 {currentVerifications.map(( user) => ( 
                                        <CustomBodyRow>
                                            <TableCell>#{user.id}</TableCell>
                                            <TableCell>{user.attributes.full_name}</TableCell>
                                            <TableCell>{user.attributes.registration_number}</TableCell>
                                            <TableCell data-testID="UserDataTest">{user.attributes.request_date}</TableCell>
                                            <TableCell>
                                                <span
                                                    style={{
                                                        ...classes.statusStyle,
                                                         ...this.handleStatusColor(user.attributes.status),
                                                         textTransform: "uppercase",
                                                    }}
                                                >
                                                    {user.attributes.status}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton data-test-id="openmodaltestID" onClick={this.handleClick}>
                                                    <MoreVertIcon data-test-id="userdataTestId" onClick={(event) => this.handleUserData(event,user.id)} style={classes.moreOpStyle} />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={dot}
                                                    keepMounted
                                                    open={Boolean(dot)}
                                                    onClose={this.handleClose}>
                                                    <MenuItem onClick={() => this.handleNavigation(user)} style={classes.VarificationMenuText1}>{languageData.View}</MenuItem>
                                                    <MenuItem data-test-id="closeboxtestID" onClick={this.handleClose} style={classes.VarificationMenuText2}>{languageData.Approve}</MenuItem>
                                                    <MenuItem onClick={this.handleClose} style={classes.VarificationMenuText3}>{languageData.Reject}</MenuItem>
                                                </Menu>
                                            </TableCell>

                                        </CustomBodyRow>
                                 ))} 
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box style={classes.paginationBox}>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <CustomPagination
                                    data-test-id="paginationId"
                                    count={this.state.total_pages}
                                    page={this.state.current_page}
                                    onChange={this.handlePageChange}
                                    shape="rounded"
                                    variant="outlined"
                                    siblingCount={1}
                                    boundaryCount={1}
                                />
                            </Grid>
                            <Typography align="right" style={classes.paginationCount}>
                                {`${(this.state.current_page - 1) * this.state.itemsPerPage + 1} - ${Math.min(this.state.current_page * this.state.itemsPerPage, this.state.verifications.length)} of ${this.state.total_count} results`}
                            </Typography>
                        </Box>

                    </Box>
                </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(VerificationRequestManagement);
const CustomPagination = withStyles({
    ul: {
      '& .MuiPaginationItem-root': {
        color: '#0F172A',
        border: 'none',
      },
      '& .Mui-selected': {
        color: '#1D4ED8',
        fontWeight: 'bold',
        background: 'none',
      },
      '& .MuiPaginationItem-ellipsis': {
        color: '#1F2937',
      },
      '& .MuiPaginationItem-icon': {
        color: '#0F172A',
      },
      '& .MuiPaginationItem-page:not(.Mui-selected)': {
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
      }
    }
  })(Pagination);
const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});
const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    height: "44px",
    color: "#0F172A",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});
const FilterSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    }
});
const FilterInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    top: "-5px",
    "&.Mui-focused": {
        display: "none"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});
const FilterDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            top: "0px"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});
const FilterResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});
const InnerBox = styled(Box)({
    border: '1px solid #E2E8F0',
    height: '114px',
    padding: '20px',
    width: '143px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    backgroundColor: '#FFFFFF'

});
const InnerTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

});


const TableUpperBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    gap: "20px",
    flexWrap: "wrap",
});

const CustomTextFieldForm = withStyles({
    root: {
        width: '320px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
const FilterButton = styled(Button)({
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content"
});
const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap",
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});
const classes = {
    typographyHeader: {
        fontWeight: "700", fontSize: "30px", lineHeight: "44px", letterSpacing: "0.5%", color: "#0F172A",

    },
    verificationBox: {
        height: '80vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'

    },
    verificationInnerBox: {
        display: 'flex', gap: '20px', flexDirection: 'column', alignItems: 'center', height: '328px', width: '287px'

    },
    groupImg: {
        height: "166px", width: '150px'

    },
    verificationText: {
        fontSize: '18px', fontWeight: '500', textAlign: 'center', color: '#000000', lineHeight: "26px"

    },
    requestButton: {
        padding: "10px 16px", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '14px', fontWeight: '700', color: '#FFFFFF', textTransform: 'none'
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    },
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        width: "320px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    searchIconStyle: {
        color: "#94A3B8"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        height: "44px",
        width: "320px"
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7",
        
    },

    resultsText: {
        color: "#64748B",
        fontSize: "12px"
    },

    buttonMainBox: {
        display: "flex",
        gap: "16px",
        width: "325px",
        alignItems: "center",
        justifyContent: 'end'
    },

    drawerPaper: {
        padding: '16px',
        width: '320px',
    },
    filterBoxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    formControl: {
        marginBottom: '16px',
    },

    inputBox: {
        display: 'flex',
        gap: '16px',
        width: '334px',
        height: '70px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left',
    },
    filterBtn: {
        height: "10px",
        width: "15px"
    },
    inputField: {
        width: '159px',
        height: '44px',
        gap: '8px',
        border: "1px solid #CBD5E1",
        borderRadius: "8px"
    },
    inputRoot: {
        width: '320px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',

        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    searchIcon: {
        width: '17.05px',
        height: '17.06px',
        marginTop: '3.47px',
        marginLeft: '3.48px',
        color: "#94A3B8"

    },
    placeholder: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
    },
    vMainBox: {
        width: '590px',
        height: '154px',
        display: 'flex',
        gap: '20px',
        marginTop: '48px',
    },

    vImg: {
        height: '24px',
        width: '24px',
    },

    vApprovedText: {
        color: '#94A3B8',
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: '500',
    },
    vApprovedNumber: {
        color: '#0F172A',
        lineHeight: '32px',
        fontSize: '24px',
        fontWeight: '600',
        letterSpacing: '-0.5%',
    },
    VarificationTopBox: {
        background: "#FFFFFF",
        borderRadius: "12px",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        padding: "16px",
        margin: "20px 0 80px 0",

    },
    paginationCount: {
        whiteSpace: "nowrap",
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400
      },
    VarificationTable: {
        boxShadow: "none",
        width: "100%",
        overflow: "auto",

    },
    VarificationMenuText1: {
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 400,
    },
    VarificationMenuText2: {
        fontSize: "14px",
        color: "#059669",
        fontWeight: 400,
    },
    VarificationMenuText3: {
        fontWeight: 400,
        color: "#DC2626",
        fontSize: "14px",
    },
    filterBottomBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        padding: "20px"
    },
    filterMiddleBox: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1"
    } as React.CSSProperties,
    filterCloseBox: {
        padding: "5px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center"
    },
    filterWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        position: "absolute",
        top: "56px",
        background: "white",
        zIndex: 10,
        right: "0px"
    } as React.CSSProperties,
    filterHeadingBox: {
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        gap: "8px"
    },
    filterLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#334155"
    },
    createButton: {
        display: 'flex',
        gap: "10px"
    },
    mainResultBox: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: "16px"
    } as React.CSSProperties,

    paginationBox: {
        marginTop:"22px",
        display: "flex",
        alignItems: "center",
        gap: "300px"
      },

}




// Customizable Area End
/*  */