import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData} from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
interface VerificationAttributes {
    uuid: string | null;
    full_name: string | null;
    status: 'pending' | 'approved' | 'rejected' | string; 
    registration_number: string | null;
    verification_type: string | null;
    request_date: string | null;
    details: string | null;
    Company: string | null;
    Debtor: string | null;
    premuim: string | null;
    supporting_documents: (string | null)[];
  }
  
  interface VerificationData {
    id: number;
    type: string;
    attributes: VerificationAttributes;
  }

  interface ValidApiData{
    data:[VerificationData]
  }
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: { name: "All" | "Archive" | "Blocked", key: string }[],
    selectedTab: "All" | "Archive" | "Blocked"
    rowsPerPage: number,
    page: number,
    dot: HTMLElement | null,
    showFilter: boolean;
    listingType: string;
    status: string,
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;
    currentPage: number;
    verifications: VerificationData[];
    searchTerm: string;
    itemsPerPage: number,
    currentpage: number,
    selectedRequest: number;
    total_pages: number,
    current_page: number,
    total_count:number;
    statusCounts: {
        approved: number,
        pending: number,
        rejected: number,
    }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class VerificationRequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getVarificationCallId : string = "";
    changeStatusCallId : string = "";
    regularStatusCallId: string= "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs: [{ name: "All", key: "company_users" }, { name: "Archive", key: "archived_users" }],
            selectedTab: "All" ,
            rowsPerPage: 5,
            page: 0,
            dot: null,
            showFilter: false,
            listingType: " ",
            status: "",
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: "",
            currentPage: 1,
            verifications: [
                {
                  id: 0,
                  type: "",
                  attributes: {
                    uuid: null,
                    full_name: null,
                    status: "",
                    registration_number: null,
                    verification_type: null,
                    request_date: null,
                    details: null,
                    Company: null,
                    Debtor: null,
                    premuim: null,
                    supporting_documents: [null],
                  }
                }
              ],
              searchTerm: "",
              itemsPerPage: 10,
              currentpage:1,
              selectedRequest: 0,
              total_pages: 0,
              current_page: 1,
              total_count:0,
              statusCounts: {
                approved: 0,
                pending: 0,
                rejected: 0,
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            this.handleAPIFun(apiRequestCallId, responseJson);
            
          }
        // Customizable Area End
    }

    // Customizable Area Start
    /*  */
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        this.verificationRequestGet();
        // Customizable Area End
    }

    
    handleDrawerNavigation2 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle2()
        } else {
                this.handleNavigatingRoute2(routing);
        }
    };
    logoutHandle2 = async() => {
        this.handleNavigatingRoute2("Home");
    };

    handleNavigatingRoute2 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleClose = () => {
        this.setState({ dot: null });
    };
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ dot: event.currentTarget });
    };


    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    };
    handleSelect = (event: React.ChangeEvent<{ value: unknown }>, name: string) => {
        let { value } = event.target;
        if (typeof value === "string") {
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        }
    };
    handleToggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };
    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: ""
        });
    };

    handleApplyNow = () => {
        this.setState({
            showFilter: false
        });
    };

    handleAPIFun = (apiRequestCallId: string, response: { data: VerificationData[], meta: { total_pages: number, current_page: number, total_count: number, counter: { approved: number, pending: number, rejected: number } } }) => {
        if (apiRequestCallId === this.getVarificationCallId) {
            const { data, meta } = response;
    
            if (meta && meta.counter) {
                this.verificationSuccess(data, meta);
            }
        }
    };
    
    
    verificationRequestGet = (page: number = 1) => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getVarificationCallId = requestMessage.messageId;
      
        const endPoint = `${configJSON.varificationRequestEndPoint}?page=${page}&per_page=${this.state.itemsPerPage}`;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    verificationSuccess = (data: VerificationData[], meta: { total_pages: number, total_count: number, current_page: number, counter: { approved: number, pending: number, rejected: number } }) => {
        this.setState({
            verifications: data,
            total_pages: meta.total_pages,
            current_page: meta.current_page,
            total_count: meta.total_count,
            statusCounts: {
                approved: meta.counter.approved,
                pending: meta.counter.pending,
                rejected: meta.counter.rejected
            }
        });
    };
    

    handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        this.setState({ searchTerm });
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if(page >= 1 && page <= this.state.total_pages) {
            this.setState({ current_page: page }, () => {
                this.verificationRequestGet(this.state.current_page);
            });
        }
    };

    handleUserData = (event: React.MouseEvent<SVGSVGElement>, id: number) => {
        this.setState({selectedRequest: id })
    }
    async  handleNavigation(user: any) {
        await setStorageData("selectedRequest", this.state.selectedRequest)
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "dashboardRequests");
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), { user });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };
    
    // Customizable Area End
}
