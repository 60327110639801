import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
import {getStorageData } from "framework/src/Utilities";
// Define interface for Company
interface CompanyImage {
  file_name: string;
  url: string;
  size: string;
}

interface CompanyDocument {
  file_name: string;
  url: string;
  size: string;
}

interface BusinessProfile {
  name: string;
  website: string;
  industry: string;
  registration: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  services: any[]; 
  bio: string | null;
  account_id: number;
  confirmed: boolean;
  image: CompanyImage;
  documents: CompanyDocument[];
}

interface ProviderAccountImages {
  address_proof_image: CompanyImage;
  id_proof_image: CompanyImage;
}

interface ProviderAccount {
  full_name: string | null;
  activated: boolean;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  phone_number: string;
  country_code: string;
  email: string;
  gender: string | null;
  type: string;
  user_type: string;
  user_name: string | null;
  address: string | null;
  city: string;
  state: string;
  country: string;
  zipcode: string | null;
  business_name: string | null;
  tax_number: string | null;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  kyc_name: string | null;
  kyc_number: string | null;
  kyc_verified: boolean | null;
  date_of_birth: string | null;
  bank_name: string | null;
  agency_name: string;
  service_provider_name: string | null;
  email_otp_verify: boolean;
  commercial_number: string;
  license_number: string;
  signup_status: string;
  last_visit_at: string | null;
  profile_image: string;
  images: ProviderAccountImages;
}

interface DeptPortfolio {
  portfolio_size: number;
  account_size: number;
  dept_age: number;
  average_balance: number;
  dept_type: number;
  recovery_status: number;
}

interface Company {
  business_profile: BusinessProfile;
  provider_account: ProviderAccount;
  dept_portfolio: DeptPortfolio;
}

// Define interface for Timeline
interface Timeline {
  id: number;
  desc: string;
  verification_request_id: number;
  created_at: string;
  updated_at: string;
}

// Define interface for RequestVerificationAttributes
interface RequestVerificationAttributes {
  uuid: string;
  full_name: string;
  status: string;
  registration_number: string;
  verification_type: string;
  request_date: string;
  details: string;
  Company: Company;
  timelines: Timeline[];
}

// Define interface for RequestVerificationData
interface RequestVerificationData {
  id: string;
  type: string;
  attributes: RequestVerificationAttributes;
}

// Define interface for the root data structure
interface RequestVerificationResponse {
  data: RequestVerificationData;
}

  
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs : Array<"All" | "New" | "In review" |"Draft" |"Archived">,
    selectedTab : "All" | "New" | "In review" |"Draft" |"Archived",
    rowsPerPage : number,
    page : number,
    expanded: boolean,
    accord: boolean,
    companyData: {
        HeadText: string;
        smallText: string;

    }[];
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;
    data: RequestVerificationData;
    isSubmit: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    
    // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchProfileCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs : ["All","New","In review","Draft","Archived"],
            selectedTab : "All",
            rowsPerPage : 8,
            page : 0,
            expanded: false,
            accord: false,
            companyData: configJSON.companyData,
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: "",
            data: {
              id: "",
              type: "",
              attributes: {
                  uuid: "",
                  full_name: "",
                  status: "",
                  registration_number: "",
                  verification_type: "",
                  request_date: "",
                  details: "",
                  Company: {
                      business_profile: {
                          name: "",
                          website: "",
                          industry: "",
                          registration: "",
                          country: "",
                          state: "",
                          city: "",
                          zip: "",
                          services: [],
                          bio: "",
                          account_id: 972,
                          confirmed: false,
                          image: {
                              file_name: "",
                              url: "",
                              size: ""
                          },
                          documents: [
                              {
                                file_name: "",
                                url: "",
                                size: ""
                              }
                          ]
                      },
                      provider_account: {
                          full_name: "",
                          activated: true,
                          first_name: "",
                          last_name: "",
                          full_phone_number: "",
                          phone_number: "",
                          country_code: "",
                          email: "",
                          gender: "",
                          type: "",
                          user_type: "",
                          user_name: "",
                          address: "",
                          city: "",
                          state: "",
                          country: "",
                          zipcode: "",
                          business_name: "",
                          tax_number: "",
                          created_at: "",
                          updated_at: "",
                          device_id: "",
                          unique_auth_id: "",
                          kyc_name: "",
                          kyc_number: "",
                          kyc_verified: null,
                          date_of_birth: null,
                          bank_name: null,
                          agency_name: "",
                          service_provider_name: null,
                          email_otp_verify: true,
                          commercial_number: "",
                          license_number: "",
                          signup_status: "",
                          last_visit_at: null,
                          profile_image: "",
                          images: {
                              address_proof_image: {
                                file_name: "",
                                url: "",
                                size: ""
                              },
                              id_proof_image: {
                                file_name: "",
                                url: "",
                                size: ""
                              }
                          }
                      },
                      dept_portfolio: {
                          portfolio_size: 1,
                          account_size: 1,
                          dept_age: 1,
                          average_balance: 1,
                          dept_type: 1,
                          recovery_status: 1
                      }
                  },
                  timelines: [
                      {
                          id: 0,
                          desc: "",
                          verification_request_id: 0,
                          created_at: "",
                          updated_at: ""
                      }
                  ]
              }
          },
          isSubmit: false,
  
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
            if (
                getName(MessageEnum.RestAPIResponceMessage) === message.id &&
                this.fetchProfileCallId != null &&
                this.fetchProfileCallId ===
                  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
              ) {
                let responseJson = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (responseJson && !responseJson.errors && responseJson.data) {
                  this.setState({ data: responseJson.data });                  
                }
              }
        // Customizable Area End
    }

    // Customizable Area Start
/*  */
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        this.fetchProfile(await getStorageData("selectedRequest"))
        // Customizable Area End
    }
 
    handleChange = () => {
        this.setState((prevState) => ({
          expanded: !prevState.expanded,
        }));
      };
     handleAccordian = () => {
        this.setState((prevState) => ({
            accord: !prevState.accord,
        }));
      };

      handleDrawerNavigation3 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle3()
        } else {
                this.handleNavigatingRoute3(routing);
        
        }
    };
    logoutHandle3 = async() => {
        this.handleNavigatingRoute3("Home");
    };


    handleNavigatingRoute3 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    fetchProfile = async (selectedRequest : string) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.varificationRequestShowEndPoint}${selectedRequest}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token" : await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    formatDate = (dateString: string | null) => {
        if (!dateString) return ''; 
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };
    // Customizable Area End
}
