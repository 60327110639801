//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData } from "../../../framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslation = require('../../languagesupport/src/LanguageData/ProductDescription.json');


interface Attributes {
  id: number;
  total_number_of_debts: number;
  total_amount_of_debts: number;
  total_judgements: number;
  last_collectors_contact: number;
  last_creditors_contact: number;
  collections_used_in_past: number;
  total_months_as_costomer: number;
  pas_payment_made: boolean;
  month_average_paytime: number;
  other_documents: Document[][];
  account_id: number;
  supporting_documents: Document[];
  portfolio_size: string;
  listing_title: string;
  avg_balance_per_account: number;
  account_count: number;
  age_of_dept: string;
  type_of_dept: string;
  current_recovery_status: string;
  status: string;
  sub_status: string;
  confirmed: boolean;
  created_at: string;
  offer_count: number;
}

interface UserListing {
  id: string;
  type: string;
  attributes: Attributes;
}

interface UserListingData {
  data: UserListing[];
}


export interface ServiceListApiResponse {

  id: string,
  type: string,
  attributes: {
    company_name: string,
    company_name_arabic: string;
    about_us: string,
    about_us_arabic: string;
    unifying_number: string,
    is_verified_provider: false,
    verification_response: string,
    company_image: { [key: string]: string } | null,
    user_account: {
      id: number,
      email: string
    },
    tabs: [
      {
        achievements: null,
        achievements_arabic: null
      },
      {
        conditions: null,
        conditions: null
      },
      {
        steps: null,
        steps_arabic: null
      }
    ],
    documents: {
      business_registration: {
        file_name: string,
        url: string,
        size: string
      },
      tax_registration: {
        file_name: string,
        url: string,
        size: string
      },
      license_certification: {
        file_name: string,
        url: string,
        size: string
      }
    }
  }
}




export interface GetServicesSuccessResponse {
  data: Array<ServiceDataResponse>
}
export interface GetServicesErrorResponse {
  message: string
}

export interface ServiceDataResponse {
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string,
    description: string
    title_arabic: string;
    description_arabic: string;
  }
}

interface Document {
  file_name: string;
  url: string;
  size: string;
}
interface Account {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: string | null;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: number | null;
  business_city: string | null;
  business_state: string | null;
  business_country: string | null;
  business_name: string | null;
  business_phone_no: string | null;
  legal_bussiness_country: string | null;
  team_members: any[];
  parent_id: number | null;
  user_role: string | null;
  business_pin_code: string | null;
  business_address: string | null;
  business_email: string | null;
  store_name: string | null;
  dob: string | null;
  bussiness_address: string | null;
  notification: boolean;
  is_deactivated: boolean;
  store_member_login: boolean;
  store_member_id: number | null;
  full_name: string | null;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  city: string;
  address: string | null;
  state: string;
  country: string;
  zipcode: number;
  tax_number: string | null;
  locked: boolean | null;
  count: number;
  kyc_name: string | null;
  kyc_number: string | null;
  kyc_verified: boolean | null;
  sub_type: string;
  bank_name: string | null;
  agency_name: string | null;
  service_provider_name: string | null;
  email_otp_verify: boolean;
  license_number: string;
  commercial_number: string;
  finalized: boolean;
  signup_status: string;
  deleted_at: string | null;
  is_subscribed: boolean;
}

interface ListingAttributes {
  id: number;
  portfolio_size: string;
  listing_title: string | null;
  avg_balance_per_account: string;
  account_count: string;
  total_number_of_debts: number;
  total_amount_of_debts: number;
  total_judgements: number;
  total_months_as_costomer: number;
  pas_payment_made: boolean;
  month_average_paytime: number;
  last_collectors_contact: string;
  last_creditors_contact: string;
  collections_used_in_past: string | null;
  age_of_dept: number;
  type_of_dept: string;
  current_recovery_status: string;
  status: string;
  sub_status: string;
  confirmed: boolean;
  account: Account;
  created_at: string;
  supporting_documents: Document[][];
  other_documents: Document[][];
  offer_count: number;
}

interface UserListing {
  id: string;
  type: string;
  attributes: ListingAttributes;
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  classes: Record<string, string>;

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  serviceData: Array<ServiceDataResponse>;
  serviceListData: Array<ServiceListApiResponse>
  serviceBuyerProfilesData: Array<ServiceListApiResponse>
  serviceListDetailData: Array<ServiceListApiResponse>
  errors: string;
  currentLanguage: 'en' | 'ar',
  productData: { name: string; price: number; description: string; images: { url: string }[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id: number;
  value: number;
  cheking: string;
  tabData: string;
  data: UserListing[];
  token: string;
  amount: string,
  offerType: string,
  pitch: string,
  successMessage: string;
  openSnackbar: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  getServiceDetailsApiCallId: string = "";
  getServiceListApiCallId: string = "";
  getServiceListDetailApiCallId: string = "";
  fetchOfferApiCall: string = "";
  makeOfferApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{ url: "" }] },
      product_id: 8,
      focusedImageIndex: 1,
      value: 0,
      serviceData: [],
      serviceListData: [],
      serviceBuyerProfilesData: [],
      serviceListDetailData: [],
      errors: "",
      tabData: [],
      currentLanguage: configJSON.EN,
      token: "",
      data: {
        id: 0,
        type: "",
        attributes: {
          id: 0,
          portfolio_size: "",
          listing_title: "",
          avg_balance_per_account: "",
          account_count: "1",
          total_number_of_debts: 1,
          total_amount_of_debts: 6500,
          total_judgements: 0,
          total_months_as_costomer: 3,
          pas_payment_made: true,
          month_average_paytime: 1,
          last_collectors_contact: "",
          last_creditors_contact: "",
          collections_used_in_past: null,
          age_of_dept: 0,
          type_of_dept: "",
          current_recovery_status: "",
          status: "",
          sub_status: "",
          confirmed: false,
          account: {
            id: 976,
            first_name: null,
            last_name: null,
            full_phone_number: "",
            country_code: null,
            phone_number: 0,
            email: "",
            activated: true,
            device_id: null,
            unique_auth_id: "",
            password_digest: "",
            created_at: "",
            updated_at: "",
            user_name: null,
            platform: null,
            user_type: "debt_owner",
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: false,
            suspend_until: null,
            status: "regular",
            role_id: null,
            business_city: null,
            business_state: null,
            business_country: null,
            business_name: null,
            business_phone_no: null,
            legal_bussiness_country: null,
            team_members: [],
            parent_id: null,
            user_role: null,
            business_pin_code: null,
            business_address: null,
            business_email: null,
            store_name: null,
            dob: null,
            bussiness_address: null,
            notification: false,
            is_deactivated: false,
            store_member_login: false,
            store_member_id: null,
            full_name: null,
            stripe_id: null,
            stripe_subscription_id: null,
            stripe_subscription_date: null,
            gender: null,
            date_of_birth: null,
            age: null,
            city: "Delhi",
            address: null,
            state: "Delhi",
            country: "India",
            zipcode: 100001,
            tax_number: null,
            locked: null,
            count: 0,
            kyc_name: null,
            kyc_number: null,
            kyc_verified: null,
            sub_type: "bank",
            bank_name: "Bank A",
            agency_name: null,
            service_provider_name: null,
            email_otp_verify: true,
            license_number: "10901090",
            commercial_number: "10101010",
            finalized: true,
            signup_status: "completed",
            deleted_at: null,
            is_subscribed: false
          },
          created_at: "",
          supporting_documents: [
            [
            {
              file_name: "",
              url: "",
              size: ""
            },
            {
              file_name: "",
              url: "",
              size: "",
            }
          ]
          ],
          other_documents: [
            
              {
                file_name: "",
                url: "",
                size: "",
              }
          ],
          offer_count: 0,
        },
      },
      amount: "",
      offerType: "",
      pitch: "",
      successMessage: "",
      openSnackbar: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start  
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            
      let phoneAuthToken = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
          this.setState({ data: phoneAuthToken.card });          
          } 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.serviceResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.serviceResponseFailureCall(apiRequestCallId, responseJson);
      }


    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    if (this.props.navigation?.getParam("navigationBarTitleText")) {
      const companyId = this.props.navigation.getParam("navigationBarTitleText");
      this.getServiceListDetail(companyId);
    }
    this.setState({ currentLanguage: language, activeBtn: language === 'en' ? 'Listings' : 'شركات' });
    this.setState({ currentLanguage: language, activeBtn1: language === 'en' ? 'Buyer profiles' : 'عروض' });

    this.getServiceDetails();
    this.getServiceList();
    const token = await getStorageData("accessToken")
    this.setState({
      token: token
    })
    const selectedRequest = await getStorageData("selectedRequest");
    this.fetchOffers(selectedRequest);
  }


  serviceResponseSucessCell = async (apiRequestCallId: string, responseJson: ServiceDataResponse & ServiceListApiResponse) => {
    if (apiRequestCallId === this.getServiceDetailsApiCallId) {
      this.getServiceDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getServiceListApiCallId) {
      this.setState({ serviceListData: responseJson.data })
    }

    if (apiRequestCallId === this.getServiceListDetailApiCallId) {
      this.setState({ serviceListDetailData: responseJson?.data });
      let Info = [];
      for (const item of responseJson.data.attributes.tabs) {
        for (const keyName in item) {
          const value = item[keyName];
          Info.push({
            label: `${keyName}`,
            content: `${value}`
          });
        }
      }
      const isEnglish = this.state.currentLanguage === configJSON.EN;
      const condition = isEnglish ? 0 : 1;
      const tabData = Info.filter((_dataInfo, index) => index % 2 === condition).map((tabData) => {
        return {
          label: isEnglish ? tabData.label : this.getArabicText(tabData.label),
          content: tabData.content
        }
      })
      this.setState({ tabData: tabData })
    }
  };

  getArabicText = (keyName: string) => {
    switch (keyName) {
      case 'achievements_arabic': return languageTranslation[this.state.currentLanguage].Achievements;
      case 'conditions_arabic': return languageTranslation[this.state.currentLanguage].Conditions;
      case 'steps_arabic': return languageTranslation[this.state.currentLanguage].Steps;
    }
  }

  serviceResponseFailureCall = async (apiRequestCallId: string, responseJson: GetServicesErrorResponse) => {
    if (apiRequestCallId === this.getServiceDetailsApiCallId) {
      this.getServiceDetailsFailureCallBack(responseJson);
    }
  };

  mockApiCall = async (data: ApidataResponseMock) => {
    const { contentType, method, endPoint, body, type } = data;

    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && type === "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  fetchOffers = (id: number) => {
    const headers = {
      "Content-Type": configJSON.loginApiContentType,
      token: this.state.token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.fetchOfferApiCall = requestMessage.messageId;
  
    const endpoint =`${configJSON.ListingsShowEndPoints}${id}`;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getServiceDetails = async () => {
    this.getServiceDetailsApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getServiceEndPoint
    });
  };

  getServiceList = async () => {
    this.getServiceListApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getServiceListEndPoint
    });
  };

  getServiceListDetail = async (serviceId: string) => {
    this.getServiceListDetailApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: `${configJSON.getServiceListEndPoint}/${serviceId}`
    });
  };

  getServiceDetailsSucessCallBack = (response: GetServicesSuccessResponse) => {
    this.setState({ serviceData: response?.data });
  };

  handleSubmittionNavigate = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "UserProfile"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleNavigateForUserList = (routes: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), routes);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  productChange = (date: string): string => {
    const now = new Date();
    const createdAt = new Date(date);
    const differenceInMilliseconds = now.getTime() - createdAt.getTime();
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);
    const differenceInMonths = Math.floor(differenceInDays / 30);
    const differenceInYears = Math.floor(differenceInMonths / 12);
  
    switch (true) {
      case differenceInMinutes < 1:
        return "Just now";
      case differenceInMinutes < 60:
        return `${differenceInMinutes} minutes ago`;
      case differenceInHours < 24:
        return `${differenceInHours} hours ago`;
      case differenceInDays < 30:
        return `${differenceInDays} days ago`;
      case differenceInMonths < 12:
        return `${differenceInMonths} months ago`;
      default:
        return `${differenceInYears} years ago`;
    }
  };  
  
  productStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
}

  productValidate = () => {
    let error = false;
    if (this.productStringNullOrBlank(this.state.amount)) {
        this.setError("amount");
        error = true;
    }
    if (this.productStringNullOrBlank(this.state.offerType )) {
        this.setError("offerType");
        error = true;
    }
    return error;
}

  productMakeOffer = async () => {
    if (!this.productStringNullOrBlank()) {
      const header = {
        token: await getStorageData("accessToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.makeOfferApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      let formdata = new FormData();
      formdata.append("[data][offer_amount]", this.state.amount);
      formdata.append("[data][offer_pitch]", this.state.pitch)
      formdata.append("[data][offer_type]", this.state.offerType)
      formdata.append("[data][listing_id]", `${this.state.data.id}`);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.makeOfferEndPoint
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({
        amount: "",       
        pitch: "",        
        offerType: "", 
        successMessage: "Your offer has been successfully submitted!", 
        openSnackbar: true,   
      });
    }
  }

  handleInputChange = (field: string, value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  
  handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ offerType: e.target.value as string });
  };
  handleSnackbarClose = () => {
    this.setState({ openSnackbar: false });
  };
  // Customizable Area End
}
