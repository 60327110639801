//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start


  Card,
  CardContent,
  TextField,
  MenuItem,
  Select,
  Breadcrumbs,
  Grid

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { createTheme, styled } from "@material-ui/core/styles";
import { image_notes, image, dollar_24px, group, Path, viewcircle_s } from "./assets";
import Rating from '@material-ui/lab/Rating';
import Text from "../../../../packages/components/src/design-system/Text";
import CustomButton from "../../../../packages/components/src/design-system/Button/Button";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const languageTranslation = require('../../languagesupport/src/LanguageData/ProductDescription.json')
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },

  typography: {
    h6: {
      fontWeight: 500,
    },

    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProductDescriptionHeaderPart = () => {
    return (
      <Box style={webStyle.breadCrumbsBox}>
        <Breadcrumbs separator={<NavigateNextIcon style={webStyle.iconStyle} fontSize="medium" />} aria-label="breadcrumb">
          <Text size="sm" weight="extraBold" variant="lightGrey">{configJSON.BreadcrumbsHeadingFirst}</Text>
          <Text size="sm" weight="extraBold" variant="primary">{configJSON.BreadcrumbsHeadingSecond}</Text>
        </Breadcrumbs>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ padding: "0 120px" }}>
        <Breadcrumbs data-test-id="breadCrumsTest" separator={<NavigateNextIcon fontSize="small" />} style={{ marginTop: '2rem' }}>
          <Typography data-test-id="homePage"  color="inherit"  onClick={() => this.handleNavigateForUserList('LandingPage')}>
            {configJSON.navHome}
          </Typography>
          <Typography data-test-id="servicePage"  color="inherit"  onClick={() => this.handleNavigateForUserList('Service')}>
            {configJSON.navMarketPlace}
          </Typography>
          <Typography color="textPrimary">{configJSON.navListing}</Typography>
        </Breadcrumbs>
        <Box style={Style.mainBox}>
          <Box style={Style.cardContainer} >
            <Card style={Style.card}>
              <CardContent style={Style.cardContent}>
                <Box style={Style.headerBox}>
                  <Typography style={Style.title}>
                   {this.state.data.attributes.listing_title}
                  </Typography>
                  <Box style={Style.subHeaderBox} >
                    <Typography style={Style.activeCollection}>
                      {this.state.data.attributes.current_recovery_status}
                    </Typography>
                    <Typography
                      style={Style.postedTime} >
                      Posted {this.productChange(this.state.data.attributes.created_at)}
                    </Typography>
                  </Box>
                </Box>

                <Box style={Style.infoBox}>
                  <Box style={Style.infoItem} >
                    <img style={{ height: "23.33px", width: "23.33px" }}
                      src={dollar_24px}
                      alt="icon"
                    />
                    <Box>
                      <Typography style={Style.infoTitle}>
                        {this.state.data.attributes.portfolio_size}
                      </Typography>
                      <Typography style={Style.infoSubtitle}>
                        Portfolio Size
                      </Typography>
                    </Box>
                  </Box>

                  <Box style={Style.infoItem}>
                    <img style={{ height: "18.67px", width: "18.67px" }}
                      src={Path}
                      alt="icon"
                    />
                    <Box>
                      <Typography style={Style.infoTitle}>
                      {this.state.data.attributes.account_count}
                      </Typography>
                      <Typography style={Style.infoSubtitle}>
                        Account Count
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    style={Style.infoItem}
                  >
                    <img
                      style={{ height: "18.85px", width: "18.84px" }}
                      src={group}
                      alt="icon"
                    />

                    <Box>
                      <Typography
                        style={Style.infoTitle}
                      >
                      {this.state.data.attributes.avg_balance_per_account}
                      </Typography>
                      <Typography
                        style={Style.infoSubtitle}
                      >
                        Average Balance per Account
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  style={Style.debtInfoBox}
                >
                  <Box style={Style.debtInfoItem}>
                    <Typography
                      style={Style.debtInfoTitle}
                    >
                       {this.state.data.attributes.type_of_dept}
                    </Typography>
                    <Typography
                      style={Style.debtInfoSubtitle}
                    >
                      Type of Debt
                    </Typography>
                  </Box>

                  <Box style={Style.debtInfoItem}>
                    <Typography
                      style={Style.debtInfoTitle}
                    >
                      {this.state.data.attributes.age_of_dept}
                    </Typography>
                    <Typography
                      style={Style.debtInfoSubtitle}
                    >
                      Age of Debt
                    </Typography>
                  </Box>
                </Box>

                <Box
                  style={Style.detailedInfoBox}
                >
                  <Typography
                    style={Style.sectionTitle}
                  >
                    Detailed Information
                  </Typography>
                  <Box style={Style.detailsContainer}>
                    <Box
                      style={Style.detailsBox}
                    >
                      <Typography
                        style={Style.detailsText}
                      >
                        Total Number of debts:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.total_number_of_debts}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Total Amount of debts:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.total_amount_of_debts}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Total Judgements:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.total_judgements}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Total month as customer:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.total_months_as_costomer}</span>
                      </Typography>
                    </Box>

                    <Box
                      style={Style.detailsBox}
                    >
                      <Typography
                        style={Style.detailsText}
                      >
                        Past payment made:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.pas_payment_made}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Month average pay time:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.month_average_paytime}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Last collector contact:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.last_collectors_contact}</span>
                      </Typography>

                      <Typography
                        style={Style.detailsText}
                      >
                        Last creditor contact:{" "}
                        <span style={{ fontWeight: "400" }}>{this.state.data.attributes.last_creditors_contact}</span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={Style.documentsBox}>
                  <Typography style={Style.sectionTitle}>
                    Supporting Documentation
                  </Typography>

                  <Grid container spacing={3} style={Style.supportDocumentContainer}>
                    {this.state.data.attributes.supporting_documents && this.state.data.attributes.supporting_documents.map((documentArray, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <Card style={Style.supportDocumentCard}>
                          <Box style={Style.supportDocumentIconContainer}>
                            <img style={Style.fileImage} src={documentArray.url} alt="" />
                          </Box>
                          <Box style={Style.supportDocumentInfo}>
                            <Typography style={Style.supportDocumentTitle}>
                              {documentArray.file_name}
                            </Typography>
                            <Typography style={Style.supportDocumentSize}>
                              {documentArray.size}
                            </Typography>
                          </Box>
                          <IconButton>
                            <img src={image} alt="Icon" />
                          </IconButton>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box
                  style={Style.documentsBox}
                >
                  <Typography
                    style={Style.sectionTitle}
                  >
                    Other documents
                  </Typography>

                  <Grid container spacing={3} style={Style.documentContainer}>
                    {this.state.data.attributes.other_documents && this.state.data.attributes.other_documents.map((documentArray, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                        <Card style={Style.documentCard}>
                          <Box style={Style.documentIconContainer}>
                            <img style={Style.fileImage} src={documentArray.url} alt="" />
                          </Box>
                          <Box style={Style.documentInfo}>
                            <Typography style={Style.documentTitle}>
                              {documentArray.file_name}
                            </Typography>
                            <Typography style={Style.documentSize}>
                              {documentArray.size}
                            </Typography>
                          </Box>
                          <IconButton>
                            <img src={image} alt="Icon" />
                          </IconButton>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box
                  style={Style.ownerInfoBox}
                >
                  <Typography
                    style={Style.sectionTitle}
                  >
                    Debt Owner Information
                  </Typography>
                  <Box
                    style={Style.ownerContainer}
                  >
                    <Box style={Style.ownerDetails}>
                      <Box
                        style={Style.ownerText}
                      >
                        <img
                          style={{
                            height: "80px",
                            width: "80px",
                            objectFit: "cover",
                            background: "#D9D9D9",
                            borderRadius: "50%",
                          }}
                          src={this.state.data.attributes.account_image}
                          alt=""
                        />
                        <Box style={{ height: "56px" }}>
                          <Typography
                            style={Style.ownerName}
                          >
                           {this.state.data.attributes.account.user_name}
                          </Typography>
                          <Typography
                            style={Style.ownerEmail}
                          >
                            {this.state.data.attributes.account.email}
                          </Typography>
                        </Box>
                      </Box>

                      <Box style={Style.ownerContactBox}
                      >
                        <Box style={Style.ownerContactItem}>
                          <Typography style={Style.contactTitle}> {this.state.data.attributes.account.phone_number}</Typography>
                          <Typography style={Style.contactSubtitle}>Phone Number</Typography>
                        </Box>

                        <Box style={Style.ownerContactItem}>
                          <Typography style={Style.contactTitle}>{this.state.data.attributes.account.country}</Typography>
                          <Typography style={Style.contactSubtitle}>Country</Typography>
                        </Box>

                        <Box style={Style.ownerContactItem}>
                          <Typography style={Style.contactTitle}>{this.state.data.attributes.account.state}</Typography>
                          <Typography style={Style.contactSubtitle}>State</Typography>
                        </Box>

                        <Box style={Style.ownerContactItem}>
                          <Typography style={Style.contactTitle}>{this.state.data.attributes.account.city}</Typography>
                          <Typography style={Style.contactSubtitle}>City</Typography>
                        </Box>

                        <Box style={Style.ownerContactItem}>
                          <Typography style={Style.contactTitle}>{this.state.data.attributes.account.zipcode}</Typography>
                          <Typography style={Style.contactSubtitle}>Zip</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box style={Style.offerBox}>
          <Snackbar
              data-test-id="menu-modal"
              open={this.state.openSnackbar}
              autoHideDuration={6000}
              onClose={this.handleSnackbarClose}
            >
              <Alert onClose={this.handleSnackbarClose} severity="success">
                {this.state.successMessage}
              </Alert>
            </Snackbar>
            <Typography variant="h6" style={Style.offerTitle}>
              Make Offer
            </Typography>
            <Box style={Style.offerForm}>
              <Typography style={Style.offerLabel}>
                Offer Type
              </Typography>
              <Select
                data-test-id="offer-type"
                defaultValue={"1"}
                fullWidth
                style={Style.selectBox}
                variant="outlined"
                IconComponent={ExpandMoreIcon}
                value={this.state.offerType}
                name="offerType"
                onChange={this.handleSelectChange}>
                <MenuItem value="1" disabled>Select Offer Type</MenuItem>
                <MenuItem value="2">Offer type 1</MenuItem>
                <MenuItem value="3">Offer type 2</MenuItem>
              </Select>
            </Box>
            <Box style={Style.purchaseBox}>
              <Typography style={Style.purchaseHead}>
                Enter purchase amount
              </Typography>
              <TextField fullWidth
              data-test-id="amountId"
              value={this.state.amount}
              name="amount"
              onChange={(event) => this.handleInputChange('amount', event.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: <Box component="span" style={Style.purchaseInput}>$</Box>,
                }}
                placeholder="0.00"
                style={Style.purchaseInputSec}
              />
            </Box>
            <TextField fullWidth
              multiline
              placeholder={configJSON.offerDescription}
              minRows={11}
              variant="outlined" s
              tyle={{ borderRadius: '8px', border: '1px solid #CBD5E1' }}
              data-test-id="pitchId"
              inputProps={{
                style: {
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#94A3B8',
                  wordSpacing: '6px',
                  height: "271px",

                },
              }}
              value={this.state.pitch}
              onChange={(event) => this.handleInputChange('pitch', event.target.value)}
            />
            <Button
              data-test-id="make-offer"
              variant="contained"
              fullWidth
              style={Style.puchaseBtn}
              onClick={this.handleMakeOffer}>
              Make Offer
            </Button>
          </Box>
          
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export { Rating };

const Style = {
  mainBox: {
    display: 'flex', gap: '24px', justifyContent: 'center',
  },
  cardContainer: {
    padding: '40px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px', marginTop: '20px', marginBottom: '100px', borderRadius: '24px', width: '895px', height: '1304px', display: 'flex', flexDirection: "column", gap: '24px'
  },
  card: {
    width: "100%", boxShadow: "none"
  },
  cardContent: {
    display: "flex", flexDirection: "column", gap: "24px"
  },
  headerBox: {
    height: "70px"
  },
  title: {
    fontSize: '24px', fontWeight: '700', color: '#0F172A'
  },
  subHeaderBox: {
    display: "flex", gap: "24px", alignItems: "center", position: "relative", top: "10px"
  },
  activeCollection: {
    backgroundColor: "#D1FAE5", 
    color: "#059669", 
    borderRadius: "40px", 
    padding: "5px 10px", 
    fontWeight: "700", 
    fontSize: "12px",
    textTransform:"uppercase"
  },
  postedTime: {
    fontSize: "14px", fontWeight: "500", color: "#64748B"
  },
  infoBox: {
    height: "98px", borderTop: "1px solid #E2E8F0", gap: "72px", display: "flex"
  },
  infoItem: {
    height: "58px", display: "flex", gap: "12px", marginTop: "40px"
  },
  infoTitle: {
    color: "#0F172A", fontWeight: "600", fontSize: "18px"
  },
  infoSubtitle: {
    color: "#334155", fontWeight: "400", fontSize: "16px", marginTop: "8px"
  },
  debtInfoBox: {
    height: "98px",
    borderTop: "1px solid #E2E8F0",
    gap: "72px",
    display: "flex",
  },
  debtInfoItem: {
    height: "58px", width: "100px", marginTop: "38px"
  },
  debtInfoTitle: {
    fontSize: "18px", fontWeight: "600", color: "#0F172A"
  },
  debtInfoSubtitle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#334155",
    marginTop: "8px",
  },
  detailedInfoBox: {
    height: "248px",
    borderTop: "1px solid #E2E8F0",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {
    marginTop: "36px",
    fontWeight: "600",
    fontSize: "20px",
    color: "#0F172A",
  },
  detailsContainer: {
    display: "flex", gap: "72px"
  },
  detailsBox: {
    height: "156px",
    width: "285px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  detailsText: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "600",
  },
  documentsBox: {
    height: "156px",
    borderTop: "1px solid #E2E8F0",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  documentContainer: {
    display: "flex",
    gap: "24px"
  },
  supportDocumentContainer: {
    gap: "24px",
    display: "flex",
  },
  documentBox: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    gap: "4px",
  },
  documentBox1: {
    flexDirection: "column",
    width: "50%",
    display: "flex",
    gap: "4px",
  },
  documentBox2: {
    gap: "4px",
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  documentBox3: {
    width: "50%",
    flexDirection: "column",
    display: "flex",
    gap: "4px",
  },
  documentCard: {
    alignItems: "center",
    padding: "8px 4px 8px 8px",
    display: "flex",
    gap: "16px",
  },
  supportDocumentCard: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "8px 4px 8px 8px",
  },
  documentIconContainer: {
    borderRadius: "8px",
    backgroundColor: "#DBEAFE",
    gap: "10px",
    display: "flex",
    padding: "16px",
  },
  supportDocumentIconContainer: {
    gap: "10px",
    backgroundColor: "#DBEAFE",
    display: "flex",
    padding: "16px",
    borderRadius: "8px",
  },
  documentInfo: {
    flex: "1 0 auto",
    marginLeft: "4px"
  },
  supportDocumentInfo: {
    marginLeft: "4px",
    flex: "1 0 auto",
  },
  fileImage: {
    height:"24px",
    width:"24px",
    borderRadius:"8px"
  },
  documentSize: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#64748B",
  },
  supportDocumentSize: {
    color: "#64748B",
    fontSize: "16px",
    fontWeight: "400",
  },
  documentTitle: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#0F172A",
  },
  supportDocumentTitle: {
    color: "#0F172A",
    fontWeight: "400",
    fontSize: "16px",
  },
  ownerInfoBox: {
    height: "254px",
    borderTop: "1px solid #E2E8F0",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  },
  ownerContainer: {
    height: "162px",
  },
  ownerDetails: {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  ownerText: {
    height: "80px",
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },

  ownerName: {
    color: "#0F172A",
    fontSize: "20px",
    fontWeight: "600",
  },
  ownerEmail: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "400",
  },
  ownerContactBox: {
    height: "58px", display: "flex", gap: "12px", alignItems: "center", justifyContent: "space-between"
  },
  ownerContactItem: {
    height: "58px", display: "flex", flexDirection: "column", gap: '8px'
  },
  contactTitle: {
    color: "#0F172A", fontSize: "18px", fontWeight: "600"
  },
  contactSubtitle: {
    color: "#334155", fontSize: "16px", fontWeight: "400"
  },
  offerBox: {
    height: '631px',
    width: '285px',
    padding: '24px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
    marginTop: "20px",
  },
  offerTitle: {
    fontSize: '20px', fontWeight: '600', color: '#334155', height: "28px"
  },
  offerForm: {
    display: 'flex', flexDirection: 'column', gap: '4px', height: "70px"
  },
  offerLabel: {
    fontSize: '14px', fontWeight: '700', color: '#334155'
  },
  selectBox: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#94A3B8',
    border: "1px solid #CBD5E1",
    height: "64px",
    background: "transparent"
  },
  purchaseBox: {
    display: 'flex', flexDirection: 'column', gap: '4px', height: "70px"
  },
  purchaseHead: {
    fontSize: '14px', fontWeight: '700', color: '#334155'
  },
  purchaseInput: {
    marginRight: '8px', fontSize: '16px', fontWeight: '700', color: '#64748B', border: "none"
  },
  purchaseInputSec: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#94A3B8',
    borderRadius: "8px",
    height: "44px"
  },
  puchaseBtn: {
    fontSize: '16px', fontWeight: '700', background: '#1E3A8A', color: 'white', padding: '10px 16px 10px 16px', borderRadius: '8px', textTransform: 'none'
  }
}
// Customizable Area End
